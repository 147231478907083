@import  url('https://fonts.googleapis.com/css?family=Saira+Semi+Condensed:200,300,400,500,600,700&display=swap');
* {
    font-family: Arial, Helvetica, sans-serif !important;
}
body {
    /* font-family: 'Saira Semi Condensed', sans-serif !important; */
    /* background: #f2f2f2; */
}
.scrollable-element {
    scrollbar-color: #2f00ff;
  }
.app-comment {
    position: absolute;
    width: 96%;
    height: 100%;
    top: 0px;
    right: 0px;
    background: #ffffff;
    padding: 80px 10px 10px 10px;
    z-index: 1000;
}

.app-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.appBar {
    height: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
}

/* .MySidePanelOverlay {
    position: fixed;
    z-index: 1000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.177);  
    overflow: hidden;  
}

.MySidePanelOverlayHide {
    left: -100%;
}
.MySidePanelOverlayShow {
    left: 0px !important;
}

.MySidePanel {
    position: absolute;
    width: 94%;
    height: 100%;
    top: 0px;
    right: 0px;
    background: #ffffff;
    padding: 80px 15px 15px 15px;
    z-index: 1001;
    transition: 0.2s all ease-in-out;
}

.MySidePanelHide {
    right: -100%;
}
.MySidePanelShow {
    right: 0px !important;
} */
.MySidePanelInner {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    background: #ffffff;
    /* padding: 80px 15px 15px 15px; */
    z-index: 1001;
    transition: 0.2s all ease-in-out;
    overflow: auto;
}

.px-15 {
    padding: 5px 8px;
}

.MySidePanelInner-header {
    padding: 80px 10px 10px 10px;
}

.MySidePanelInnerHide {
    right: -120%;
}
.MySidePanelInnerShow {
    right: 0px !important;
}

.budget-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background: #ffffff;
    border: 1px solid #fff;
    box-shadow: 24;
    padding: 25px;
    z-index: 1600;
}

@media (min-width: 768px) {

    .budget-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30%;
        background: #ffffff;
        border: 1px solid #fff;
        /* box-shadow: 25; */
        padding: 25px;
        z-index: 1600;
    }

    .MySidePanelOverlay {
        z-index: 1200;
    }
    .MySidePanelInnerHide {
        right: -100%;
    }
    .MySidePanelInner {
        width: 50% !important;
        padding: 15px 15px 15px 15px;
        z-index: 1250;
        box-shadow: -16px 0px 120px 0 #00000017, -16px 0px 120px 0 #0069ff70;
    }
    .MySidePanelInner-header  {
        padding: 0px;
    }
    /* .MySidePanel {
        width: 50% !important;
        padding: 15px 15px 15px 15px;
        z-index: 1400;
    } */
}



.app-input {
    width: 90%;
    height: 30px;
    padding: 4px 8px;
    margin: 5px;
    outline: none;
    border-radius: 12px;
    border: 1px solid #fff;
}

.iconTile {
    background: #ffffff;
    margin: 5px;
    border-radius: 12px !important; 
    box-shadow: 0px 3px 10px 0 #004bff42, 0px 3px 20px 0 #ffffff42 !important;
}
.iconTileInner {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.iconTileImage {

}
.iconTileTitle {
    font-size: 16px;
    font-weight: 500;
    margin: 10px 0px 5px 0px;
}

#popularCars {
    padding: 12px;
    position: relative;
    margin-top: 188px;
    z-index: 100;
    padding-bottom: 65px;
    background: #e9e9e937;
    height: 100%;
    overflow: hidden;
    /* background: red; */
}
.aster-car-box-title {
    font-size: 18px;
    font-weight: 600;
    color: black;
    margin: 0px;
    margin-bottom: 4px;
}
.aster-car-box-footer {
    margin: 0px;
    margin-bottom: 0px;
    font-size: 10px;
    color: #7f7f7f;
    font-weight: 600;
}

.myPill {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 2px 6px -2px 0px;
}


/* Owl Carasoul */
.owl-nav button {
    position: absolute;
    top: 50%;
    background-color: #000;
    color: #fff;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  .owl-nav button.owl-prev {
    left: 0;
  }
  .owl-nav button.owl-next {
    right: 0;
  }
  
  .owl-dots {
    text-align: center;
    padding-top: 15px;
  }
  .owl-dots button.owl-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background: #ccc;
    margin: 0 3px;
  }
  .owl-dots button.owl-dot.active {
    background-color: #000;
  }
  .owl-dots button.owl-dot:focus {
    outline: none;
  }
  .owl-nav button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(255, 255, 255, 0.38) !important;
  }
  .owl-nav span {
      font-size: 70px;    
      position: relative;
      top: -5px;
  }
  .owl-nav button:focus {
      outline: none;
  }

  .bottomNav {
    width: 100%;
    /* width: 94.5%; */
    position: fixed;
    left: 0px;
    /* bottom: 5px; */
    bottom: 0px;
    /* margin: 10px; */
    box-shadow: 0 0 16px 0 #00000091;
    background: #ffffff;
    /* border-radius: 20px; */
    overflow: auto;
    box-shadow: 2px -5px 10px 0 #004bff29, 0px 2px 20px 0 #ffffff42;
    z-index: 1000;
  }

.my-card {
    border-radius: 8px !important;
    border: 1px solid #3333331c;
    box-shadow: 0 0 0 0 #333 !important;
}

.my-card-inner {
    padding: 3px 5px !important;
}
.rating-box {
    background: #4caf50;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 2px 4px;
    border-radius: 4px;
    margin: 2px 5px 2px 0px;
    float: left;
}


.my-textarea {
    width: 95%;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 13px 10px;
    outline: none;
}

.drawerItemTitle {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin: 5px 0px 2px 0px;
}
.drawerHeader {
    width: 100%;
    height: 160px;
    background: #333;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.loading {
    filter: blur(10px);
    clip-path: inset(0);
  }
  .loaded {
    filter: blur(0px);
    transition: filter 0.5s linear;
  }

  .img-label {
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .custom-file-input {
    margin-bottom: 10px;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select Images';
    display: inline-block;
    background: linear-gradient(181deg, #054384, #1c4fa0);
    border: 1px solid #0c468c;
    border-radius: 4px;
    padding: 10px 10px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
    color: #ffffff
  }
  .custom-file-input:hover::before {
    border-color: rgb(255, 255, 255);
  }
  .custom-file-input:active::before {
    background: linear-gradient(181deg, #054384, #1c4fa0);
  }
  .bookedText {
    position: absolute;
    z-index: 1;
    margin: 11% 27%;
    font-size: 24px;
    transform: rotate(346deg);
    color: orangered;
    text-shadow: 0 0 9px black;
    border: 3px solid orangered;
    padding: 3px 16px;
    box-shadow: inset 0 0 20px 0 #333;
}
.mycardBooked::after {
	content: 'BOOKED';    	
    z-index: 1200;   	
    color: red;
    position: absolute;	
    margin: 3% 6%;
    font-size: 30px;
    font-weight: 600;
    text-shadow: 1px 1px 0px #ffffff !important;
    border: 4px solid red;
    padding: 2px 7px;
    transform: rotate(343deg);
    background: #000000;
}

@media (max-width: 768px) {
    .mycardBooked::after {
        margin: 9% 26% !important;
    }
}

.mycardUpcoming::after {
	content: 'UPCOMING';    	
    z-index: 1200;   	
    color: #63ff00;
    position: absolute;	
    margin: 2% 6%;
    font-size: 30px;
    font-weight: 600;
    text-shadow: 1px 1px 0px #ffffff !important;
    border: 4px solid #63ff00;
    padding: 2px 7px;
    transform: rotate(343deg);
    background-color: #000000;
}

@media (max-width: 768px) {
    .mycardUpcoming::after {
        margin: 9% 26% !important;
    }
}

.my-footer {
    position: relative;
    background: #024284;
    width: auto;
    height:auto;
    bottom: 0;
    left: 0px;
    /* z-index: 1000; */
    padding: 20px 30px;
    padding-bottom: 50px;
}
.my-quick-link {
    list-style-type: disc;
    color: #fff;
}
.my-quick-link li {
    padding: 5px 0px;
}
.my-quick-link li a{
    text-decoration : none;
    color: #ffffff;
}
.floating-alert{
    position: fixed;
    width: 100%;
    height: auto;
    z-index: 1001;
    background: #ff7500;
    margin: 0px;
}

.floating-alert h2{ 
    margin: 0px;
    font-size: 18px;
    color: #fff;
    margin-top: 5px;
}

.CarCarousel.owl-carousel .owl-item img{
    display: block;
    width: 100%;
    object-fit: cover;
}

@media (max-width: 590px) {
    .my-footer {
      display: none !important;
    }
  }
  

@media (min-width: 300px) {
    .css-hyum1k-MuiToolbar-root {
        min-height: 64px;
    }
}